<section class="auth-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <form class="md-float-material form-material" [formGroup]="loginForm" (ngSubmit)="loginUser()">
          <div class="log-in-box card">
            <div class="card-block">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <div class="text-center m-b-20">
                    <img class="form-logo" src="./assets/images/logo.webp" alt="Air Restore">
                  </div>
                  <h4 class="text-center">Sign in</h4>
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="error">
                <p class="m-b-0">{{error}}</p>
              </div>
              <div class="custom-container">
                <mat-form-field appearance="outline" class="">
                  <mat-label>Email ID</mat-label>
                  <input matInput placeholder="Email ID" formControlName="email" [(ngModel)]="login.username"
                    [ngClass]="{ 'is-invalid': submitted &amp;&amp; loginForm.controls.password.errors }" required>
                  <mat-error *ngIf="email.invalid">
                    <div *ngIf="submitted &amp;&amp; loginForm.controls.email.errors" class="text-danger">
                      <div *ngIf="loginForm.controls.email.errors.required">Email is required</div>
                      <div *ngIf="loginForm.controls.email.errors.email">Email must be a valid email address</div>
                    </div>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="">
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" placeholder="Enter your password"
                    [(ngModel)]="password" [type]="hide ? 'password' : 'text'"
                    [ngClass]="{ 'is-invalid': submitted &amp;&amp; loginForm.controls.password.errors }" required>
                  <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="email.invalid">{{getpwdErrorMessage()}}</mat-error>
                </mat-form-field>

                <div class="row text-left m-b-1">
                  <div class="col-12">
                    <div class="d-inline-block col-6">
                      <a routerLink={{otpLogin}} class="text-right f-b-5">Login with OTP</a>
                    </div>

                    <div class="text-right float-right col-6">
                      <a routerLink={{forgotPassword}} class="text-right f-b-5"> Forgot Password?</a>
                    </div>
                  </div>
                </div>
                <div>
                  <button mat-raised-button type="submit" class="button-red" [disabled]="!loginForm.valid || spinner">{{signin}}<div class="lds-dual-ring" *ngIf="spinner"></div></button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
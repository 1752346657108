<div class="text-center">

    <img src="../../../../assets/svgs/404-error-animate.svg" class="w-100 svg-img m-auto d-block" alt="No Page Found" />
  
    <button
    [routerLink]="[home]"
     class="m-2 w-75 border border-dark" mat-button *ngIf="isLoggedIn ">
        <i class="feather icon-arrow-left text-dark f-16 "></i>
        Home</button>
   
        <button 
        [routerLink]="[login]"
        class="m-2 w-75 border border-dark" mat-button *ngIf="!isLoggedIn ">
        <i class="feather icon-arrow-left text-dark f-16 "></i>
        Login</button>
</div>
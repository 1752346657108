import { RouteConst } from './_const/route';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  CanActivateChild, Router, RouterStateSnapshot
} from '@angular/router';
import { Menu } from './_models/menu';
import { Session } from './_utils/session';
import { AuthenticationService } from './auth.service';
import EncryptUtil from './_utils/encrypt';
import { AuthConst } from './_const/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private appSession: Session,
    private authenticationService: AuthenticationService
  ) {

  }

  private menus: Menu[];
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const isUserLoggedIn = this.authenticationService.isLoggedIn();
    if (isUserLoggedIn) {
      this.appSession.getProfile();
      if (!this.appSession.profile) {
        return false;
      }

      const menus = JSON.parse(JSON.stringify(this.appSession.menus));
      if (route.url.length === 0) {
        this.router.navigate([RouteConst.APP_DASHBOARD]);
        return true;
      }
      const result = this.checkRoute(state.url, menus);
      if (!result) {
        this.router.navigate([''], { queryParams: { url: state.url } });
      }
      return result;
    } else {
      const lockScreen = localStorage.getItem(AuthConst.LOCK_SCREEN);
      if (lockScreen !== null) {
        const lockScreenKey = EncryptUtil.decryptString(lockScreen);
        if (lockScreenKey) { return true; }
      }
      if (route.url.length === 0) { return true; }
      this.router.navigate([''], { queryParams: { url: state.url } });
      return false;
    }
  }

  checkRoute(url: string, menus: Menu[]): boolean {
    return true;

    if (url !== '/') {
      this.authenticationService.redirectUrl = url;
    }
    let allowRoute: boolean; if (menus.length !== 0 && !allowRoute) {
      menus.some(menu => {
        if (url.includes('?')) {
          url = url.substr(0, url.indexOf('?'));
        }

        if (menu.menuUrl.includes('***')) {
          menu.menuUrl = menu.menuUrl.substr(0, menu.menuUrl.indexOf('***'));
          if (url.startsWith(menu.menuUrl)) {
            return allowRoute = true;
          }
        }
        if (this.checkByRegex(url, menu.menuUrl)) {
          allowRoute = true;
        } else if (menu.childMenus.length !== 0) {
          allowRoute = this.checkRoute(url, menu.childMenus);
        } else if (menu.menuLinks.length !== 0) {
          allowRoute = this.checkRoute(url, menu.menuLinks);
        }
        if (allowRoute) {
          return allowRoute;
        }
      });
    }
    return allowRoute;
  }

  checkByRegex(route: string, menuUrl: string): boolean {
    const slashPattern = menuUrl.replace(/[^a-z0-9-*#]/g, '\\/');
    const alphaPattern = slashPattern.replace(/s\*/g, '[A-Za-z]+');
    const numericPattern = alphaPattern.replace(/d\*/g, '(?:\\d*)') + '$';
    const alphaNumericPattern = numericPattern.replace(/t\*/g, '[A-Za-z0-9]+');
    const regex = new RegExp(alphaNumericPattern);
    return regex.test(route);

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const menus = JSON.parse(JSON.stringify(this.appSession.menus));
    if (this.checkRoute(state.url, menus)) {
      return true;
    }
    this.router.navigate([RouteConst.APP_DASHBOARD]);
    return false;
  }
}

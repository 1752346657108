import { ShortnamePipe } from './_pipes/shortname.pipe';
import { NgModule } from '@angular/core';
import { TimeAgoFormatPipe } from './_pipes/timeago.pipe';
import { WhatsappTimeAgoFormatPipe } from './_pipes/whatsappTimeAgo.pipe';
import { FormatTextPipe } from './_pipes/formatText.pipe';
import { SafeSanitizeHtmlPipe } from './_pipes/safeSanitizeHtml.pipe';
import { DaysFormatPipe } from './_pipes/DaysFormatPipe.pipe';
import { MinuteSecondsPipe } from './_pipes/minuteSeconds.pipe';
import { TimeFormatPipe } from './_pipes/timeFormat.pipe.';

@NgModule({
    imports: [],
    declarations: [
        ShortnamePipe,
        TimeAgoFormatPipe,
        WhatsappTimeAgoFormatPipe,
        FormatTextPipe,
        SafeSanitizeHtmlPipe,
        DaysFormatPipe,
        MinuteSecondsPipe,
        TimeFormatPipe
    ],
    exports: [
        ShortnamePipe,
        TimeAgoFormatPipe,
        WhatsappTimeAgoFormatPipe,
        FormatTextPipe,
        SafeSanitizeHtmlPipe,
        DaysFormatPipe,
        MinuteSecondsPipe,
        TimeFormatPipe
    ]
})
export class SharedPipesModule { }

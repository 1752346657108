import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Login } from '../_models/login';
import { Message } from '../_const/message';
import { Constants } from '../_const/constants';
import { RouteConst } from '../_const/route';
import { Title } from '@angular/platform-browser';
import SnackBar from '../_utils/snackbar';
import { AuthenticationService } from '../auth.service';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {


  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    public appComponent: AppComponent,
  ) {
    this.titleService.setTitle('Login' + Constants.APP_TITLE);
  }

  loginForm: FormGroup;
  submitted = false;
  hide = true;
  login: Login = new Login();
  error: string;
  spinner = false;
  signin = Constants.SIGNIN;
  email = new FormControl('', Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/));
  pwd = new FormControl('', [Validators.required]);
  password: string;
  forgotPassword: string = RouteConst.FORGOT_PASSWORD;
  otpLogin: string = RouteConst.OTP_LOGIN;

  getpwdErrorMessage() {
    return this.pwd.hasError(Constants.REQUIRED) ? Message.PASSWORD_ERROR : '';
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      password: ['', [Validators.required]],
      remember: []
    });
  }

  loginUser() {
    this.submitted = true;
    this.signin = Constants.EMPTY_STRING;
    this.spinner = true;
    this.login.password = this.password;
    let remember = this.loginForm.get(Constants.REMEMBER).value;
    if (remember !== true) {
     remember = false;
    }
    if (this.loginForm.invalid) { return; }
    this.authenticationService.login(this.login).subscribe((response: any) => {
    }, (error) => {
      this.spinner = false;
      this.signin = Constants.SIGNIN;
      this.password = '';
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Login } from '../_models/login';
import { Constants } from '../_const/constants';
import { RouteConst } from '../_const/route';
import { Title } from '@angular/platform-browser';
import SnackBar from '../_utils/snackbar';
import { AuthenticationService } from '../auth.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss']
})

export class OTPLoginComponent implements OnInit {


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    public appComponent: AppComponent,
  ) {
    this.titleService.setTitle('Login' + Constants.APP_TITLE);

    const currentUser = this.authenticationService.isLoggedIn();
    if (currentUser) {
      this.router.navigate([RouteConst.APP_DASHBOARD]);
    }
  }

  loginForm: FormGroup;
  submitted = false;
  hide = true;
  login: Login = new Login();
  error: string;
  spinner = false;
  signin = Constants.GETOTP;
  email = new FormControl('', Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/));
  // pwd = new FormControl('', [Validators.required]);
  // password: string;
  loginWithPassword: string = RouteConst.LOGIN;

  // getpwdErrorMessage() {
  //   return this.pwd.hasError(Constants.REQUIRED) ? Message.PASSWORD_ERROR : '';
  // }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      //password: ['', [Validators.required]]
    });
  }

  loginUser() {
    this.submitted = true;
    this.signin = Constants.EMPTY_STRING;
    this.spinner = true;
    //this.login.password = this.password;
    
    if (this.loginForm.invalid) { return; }

    this.login.loginMethod = Constants.EMAIL;

    this.authenticationService.sendOTP(this.login)
    .subscribe((response: string) => {
      this.login.confirmPassword = response;
      
      localStorage.setItem(Constants.LOGIN_DATA, JSON.stringify(this.login))
      
      this.spinner = false;
      this.signin = Constants.SIGNIN;
      this.router.navigate([RouteConst.VERIFY_OTP]);
    }, (error: string) => {
      this.spinner = false;
      this.signin = Constants.SIGNIN;
      //this.password = '';
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }
}

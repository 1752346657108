import { RoleService } from '../home/masters/manage-role/role.service';
import { Profile } from 'src/app/_models/profile';
import { MenuRole } from 'src/app/_models/menu-role';
import { RoleAuthorityService } from '../home/masters/role-authority/role-authority-service.service';
import { Menu } from '../_models/menu';
import { Injectable, Injector } from '@angular/core';
import EncryptUtil from '../_utils/encrypt';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth.service';


@Injectable({
    providedIn: 'root'
})
export class Session {
    constructor(
        public router: Router,
        private roleService: RoleService,
        private injector: Injector) {
    }
    menus: Menu[] = [];
    menuRole: MenuRole;
    profile: Profile;
    employeeMenus: Menu[];

    getProfile() {
        this.menus = [];
        const menuSession = localStorage.getItem('menus');
        const profileSession = localStorage.getItem('profile');
        const auth = this.injector.get(AuthenticationService);
        if ((menuSession === '' || menuSession === null) && (profileSession === '' || profileSession === null)) {
            this.profile = this.roleService.profile;
            if (!this.profile || this.profile === undefined) {
                // auth.logout();

            } else {
                this.getInitMenus(this.profile);
            }
        } else {
            const decryptedMenus = EncryptUtil.decryptJson(menuSession);
            const decryptedProfile = EncryptUtil.decryptJson(profileSession);

            this.menus = decryptedMenus;
            this.profile = decryptedProfile;
        }

        return this.profile;
    }

    getInitMenus(profile: Profile) {
        if (this.menus.length === 0) {
            this.menuRole = profile.menuRole;
            this.menus = this.filterMenus(this.menuRole.menuList);
        }
        localStorage.setItem('menus', EncryptUtil.encryptJson(this.menus));
        localStorage.setItem('profile', EncryptUtil.encryptJson(this.profile));
        return this.menus;
    }

    filterMenus(menus: Menu[]) {
        let menuList: Menu[];
        if (menus.length >= 1) {
            menuList = menus.filter(menu => menu.menuRoleStatus === 1);
            menuList.forEach(menu => {
                menu.childMenus = this.filterMenus(menu.childMenus);
                menu.menuLinks = this.filterMenus(menu.menuLinks);
            });
            menus = menuList;
        }
        return menus;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { AuthConst } from './_const/auth';
import EncryptUtil from './_utils/encrypt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Air Restore';
  isLock = false;
  renewFailSubscription: Subscription;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService) {
    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.matDialog.closeAll())
    ).subscribe();
    window.addEventListener('storage', this.storageEventListener.bind(this));
    
    window.addEventListener('online', () => {
      this.authenticationService.appInitializeCheckUp().subscribe();
    });
  }

  private storageEventListener(event: StorageEvent) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

}

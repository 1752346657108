import {
    MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { Constants } from '../_const/constants';



export default class SnackBar {

    constructor(public snackBar: MatSnackBar) { }

    static open(snackBar: MatSnackBar, response: string, message: string, variant?: string) {
        let className = null;
        switch (variant) {
            case Constants.SUCCESS:
                className = 'bg-success';
                break;
            case Constants.ERROR:
                className = 'bg-danger';
                break;
            default:
                break;
        }
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.horizontalPosition = 'center';
        config.duration = true ? 2000 : 0;
        config.panelClass = [className, 'text-white'];

        return (snackBar.open(response, message, config));

    }


}



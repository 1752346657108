import { Injectable, Injector, ViewChild } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        public router: Router, private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(AuthenticationService);
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                auth.clearLocalStorage();
                this.router.navigate(['']);
            }
            const error = err.error || err.message || err.statusText;
            return throwError(error);
        }));
    }
}

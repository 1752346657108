import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularWebStorageModule } from 'angular-web-storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginComponent } from './login/login.component';
import { OTPLoginComponent } from './otp-login/otp-login.component';
import { CookieService } from 'ngx-cookie-service';
import { NgOtpInputModule } from 'ng-otp-input';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/_helper/auth.interceptor';
import { ErrorInterceptor } from 'src/app/_helper/error.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { ExportAsModule } from 'ngx-export-as';
import { appInitializer } from './app-intializer';
import { AuthenticationService } from './auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentUtcDateAdapter } from './moment-utc-date-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { SharedPipesModule } from './sharedPipes.module';
import { ErrorHandlerService } from './_helper/errorhandler.service';
import { LoggerService } from './_helper/logger.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatDialogModule } from '@angular/material/dialog';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    LoginComponent,
    OTPLoginComponent,
    ForgotPasswordComponent,
    VerifyOTPComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularWebStorageModule,
    NgOtpInputModule,
    PerfectScrollbarModule,
    FontAwesomeModule,
    ExportAsModule,
    NgSelectModule,
    MatDatepickerModule, // provides moment date adapter
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    SharedPipesModule,
    EditorModule,
    MatDialogModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true })
  ],
  providers: [
    CookieService,
    LoggerService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
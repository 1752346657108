import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { EndPoints } from '../_const/endpoints';
import { throwError } from 'rxjs';
import EncryptUtil from '../_utils/encrypt';

@Injectable({
  providedIn: 'root'
})
export class ForgotService {

  constructor(private http: HttpClient) { }
  // for verifying otp
  verifyOtp(username: string, otp: string, otpToken: string): any {
    const body = { 
      username: username,
      password: otp,
      confirmPassword: otpToken,
      loginMethod: "email" 
    };

    return this.http.post(EndPoints.VERIFY_OTP, body, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  // for resending otp
  resendOtp(username: string, otpToken: string): any {
    const body = { 
      username: username,
      loginMethod: "email",
      confirmPassword: otpToken
    };

    return this.http.post(EndPoints.RESEND_OTP, body, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  sendMail(username: string): any {
    const body = { 
      username: username,
      loginMethod: "email" 
    };

    return this.http.post(EndPoints.SEND_OTP, body, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  // for change password
  changePassword(username: string, password: string, token: string): any {
    password = EncryptUtil.baseEncodePassword(password);
    const body = { username, password };
    return this.http.post(EndPoints.CHANGE_PASSWORD + token, body, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  updatePassword(password: string, confirmPassword: string): any {
    password = EncryptUtil.baseEncodePassword(password);
    confirmPassword = EncryptUtil.baseEncodePassword(confirmPassword);

    const body = { password, confirmPassword };
    return this.http.put(EndPoints.UPDATE_PASSWORD, body, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

}

<section class="auth-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="log-in-box card">
          <div class="text-center m-b-20 p-t-125-rem">
            <img
              class="form-logo"
              src="./assets/images/logo.webp"
              alt="Air Restore"
            />
          </div>
          <div class="p-125-rem pt-0">
            <form
              class="md-float-material form-material"
              [formGroup]="verifyForm"
              (ngSubmit)="verifyOtp()"
            >
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h4 class="text-center">Enter the OTP</h4>
                </div>
              </div>
              <div class="custom-container otp_verify">
                <p class="m-b-1">
                  <ng-otp-input
                    #ngOtpInput
                    (onInputChange)="otpChange($event)"
                    [config]="config"
                    required
                  ></ng-otp-input>
                  <input
                    type="text"
                    *ngIf="otp"
                    formControlName="votp"
                    value="{{ otp }}"
                    class="o-t-p"
                    hidden
                  />
                </p>
                <div class="m-b-20">
                  <button mat-raised-button class="button-red"
                  [disabled]="!otp || otp.length !== config.length || loading">
                    <span *ngIf="!loading">Verify OTP</span>
                    <div class="lds-dual-ring" *ngIf="loading"></div>
                  </button>
                </div>
                <p class="text-right">
                  <span (click)="resendOtp()" class="text-right f-b-5 r_otp">
                    Resend OTP</span
                  >
                  <a
                    routerLink="{{ loginPage }}"
                    class="text-right br-l ml-2 pl-2"
                  >
                    Back to Login</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ForgotService } from './forgot-password.service';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Login } from '../_models/login';
import { Title } from '@angular/platform-browser';
import { AuthConst } from '../_const/auth';
import { RouteConst } from '../_const/route';
import { Message } from '../_const/message';
import { Constants } from '../_const/constants';
import SnackBar from '../_utils/snackbar';
import { AuthenticationService } from '../auth.service';

@Component({
  selector: 'app-fmgk-fgt-pass',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {
  changeForm: FormGroup;
  forgotForm: FormGroup;
  verifyForm: FormGroup;

  showBlock: string;
  errorMessage: any;
  hide = true;
  loading = false;
  submitted = false;
  otp: any;
  loginPage: string = RouteConst.LOGIN;
  passwordHeading: string;

  votp = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  pwd = new FormControl('', [Validators.required]);

  currentUserName: string;
  resetToken: string;
  otpToken: string;

  changePwdBtnLabel: string = 'Reset Password';

  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
    inputStyles: {
      width: '50px',
      height: '50px'
    }
  };

  getErrorMessage() {
    return this.email.hasError(Constants.REQUIRED) ? Message.EMPTY_VALUE :
      this.email.hasError(Constants.EMAIL) ? Message.EMAIL_ERROR : Constants.EMPTY_STRING;
  }

  getpwdErrorMessage() {
    return this.pwd.hasError(Constants.REQUIRED) ? Message.PASSWORD_ERROR : '';
    this.loading = false;
  }


  constructor(
    private formBuilder: FormBuilder,
    private forgotService: ForgotService,
    public session: SessionStorageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private activeroute: ActivatedRoute,
    private titleService: Title,
    private authenticationService: AuthenticationService) {
    /**const currentUser = this.authenticationService.isLoggedIn();
    if (currentUser) {
      this.router.navigate([RouteConst.APP_DASHBOARD]);
    }**/
    if (this.activeroute.snapshot.url[0].path === 'set-password') {
      this.showBlock = 'changeBlock';
      this.passwordHeading = 'Set New Password';
      // this.activeroute.queryParams.subscribe(params => {
      //   this.currentUserName = params.email;
      //   this.resetToken = params.code;
      // });
      this.changePwdBtnLabel = 'Update Password';
      this.titleService.setTitle('Set Password' + Constants.APP_TITLE);
    } else {
      this.showBlock = 'forgotBlock';
      this.passwordHeading = 'Recover Your Password';
      this.titleService.setTitle('Forgot Password' + Constants.APP_TITLE);
    }
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])]
    });
    this.verifyForm = this.formBuilder.group({
      votp: ['', [Validators.required]]
    });
    this.changeForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  otpChange(otp: any) {
    this.otp = otp;
  }

  verifyOtp() {
    this.loading = true;
    this.forgotService.verifyOtp(this.currentUserName, this.otp, this.otpToken).subscribe((response) => {
      this.resetToken = response;
      this.showBlock = 'changeBlock';
      this.loading = false;
    }, (error) => {
      this.loading = false;
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }

  resendOtp() {
    this.forgotService.resendOtp(this.currentUserName, this.otpToken).subscribe((response) => {
      SnackBar.open(this.snackBar, "OTP sent again to your Email ID", Constants.SUCCESS, Constants.SUCCESS);
    }, (error) => {
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }

  forgotAction() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }

    this.currentUserName = this.forgotForm.get(Constants.EMAIL).value;

    this.forgotService.sendMail(this.currentUserName).subscribe((response) => {
      this.showBlock = 'otpBlock';
      this.otpToken = response;
    }, (error) => {
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }

  changeAction() {
    if (this.changeForm.invalid) {
      return;
    }

    this.submitted = true;
    const password = this.changeForm.get(Constants.LOGIN_PASSWORD).value;
    const confirmPassword = this.changeForm.get(Constants.CONFIRM_PWD).value;

    if (password !== confirmPassword) {
      SnackBar.open(this.snackBar, "Confirm Password did not match", Constants.ERROR, Constants.ERROR);
      return;
    }

    if (this.changePwdBtnLabel == 'Update Password') {
      this.updateAction(password, confirmPassword);
      return;
    }

    this.forgotService.changePassword(this.currentUserName, password, this.resetToken).subscribe((response) => {
      SnackBar.open(this.snackBar, Message.PASSWORD_UPDATED, Constants.SUCCESS, Constants.SUCCESS);
      this.router.navigate([RouteConst.LOGIN]);
    }, (error) => {
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }

  updateAction(password: string, confirmPassword: string) {
    this.forgotService.updatePassword(password, confirmPassword).subscribe((response) => {
      SnackBar.open(this.snackBar, Message.PASSWORD_UPDATED, Constants.SUCCESS, Constants.SUCCESS);
      this.router.navigate([RouteConst.LOGIN]);
    }, (error) => {
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionStorageService } from 'angular-web-storage';
import { Constants } from '../_const/constants';
import { RouteConst } from '../_const/route';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    public session: SessionStorageService,
    private titleService: Title) {
    this.titleService.setTitle('Not Found' + Constants.APP_TITLE);
  }

  isLoggedIn: boolean;
  home = RouteConst.APP_DASHBOARD;
  login = RouteConst.LOGIN;

  ngOnInit(): void {
    this.checkProfile();
  }
  checkProfile() {
    const profileSession = this.session.get('profile');
    this.isLoggedIn = profileSession === '' || profileSession === null ? false : true;
  }

}

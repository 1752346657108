
import * as crypto from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { environment } from '../../environments/environment';



export default class EncryptUtil {

static base64Value() {
    const secertKey = environment.secertKey;
    const words = crypto.enc.Utf8.parse(secertKey);
    const base64 = crypto.enc.Base64.stringify(words);
    return base64;
}

static baseEncodePassword(password: string) {
    const words = crypto.enc.Utf8.parse(password);
    const base64 = crypto.enc.Base64.stringify(words);
    return base64;
}

static encryptString(password: string): string {
    const base64 = this.base64Value();
    const encrypteddata = crypto.AES.encrypt(password, base64).toString();
    return encrypteddata;
}

static encryptJson(json: any): string {
    const base64 = this.base64Value();
    const encrypteddata = crypto.AES.encrypt( JSON.stringify(json), base64).toString();
    return encrypteddata;
}

static decryptString(password: string): string {
    const base64 = this.base64Value();
    // const encrypted = this.encryptString(password);
    const bytes  = crypto.AES.decrypt(password, base64);
    const decryptedData = bytes.toString(crypto.enc.Utf8);
    return decryptedData;
}

static decryptJson(json: string): any {
    const base64 = this.base64Value();
    const bytes  = crypto.AES.decrypt(json, base64);
    const decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
}

}

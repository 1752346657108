export const Message = {
    //  login/forgot Password
    PASSWORD_ERROR: 'Password is required',
    EMPTY_VALUE: 'You must enter a value',
    EMAIL_ERROR: 'Not a valid email',
    PASSWORD_UPDATED: 'Password Changed Successfully',

    //  Products
    PRODUCT_UPDATED: 'Product Updated Successfully',
    PRODUCT_CREATED: 'Product Created Successfully',
    PRODUCT_ERROR: 'Inadequate Data, Please check errors tab',

    //  USERS
    User_UPDATED: 'User Updated Successfully',
    User_CREATED: 'User Created Successfully',
    Employee_UPDATED: 'Employee Updated Successfully',
    Employee_CREATED: 'Employee Created Successfully',
    SELLER_UPDATED: 'Seller Updated Successfully',
    Seller_CREATED: 'Seller Created Successfully',
    Customer_UPDATED: 'Customer Updated Successfully',
    Customer_CREATED: 'Customer Created Successfully',
    CUSTOMER_CONVERTED_MSG: 'Customer Converted Successfully',
    Partner_UPDATED: 'Partner Updated Successfully',
    Partner_CREATED: 'Partner Created Successfully',
    Transporter_UPDATED: 'Transporter Updated Successfully',
    Transporter_CREATED: 'Transporter Created Successfully',
    NO_USER_TYPE: 'No User Type Found',

    // DATA IMPORT
    TEMPLATE_UPDATED: 'Template Updated Successfully',
    TEMPLATE_CREATED: 'Template Created Successfully',
    NOT_VALID_TEMPLATE: 'Not a valid Template',
    SELECT_ALL_ENTITIES: 'Select All Entities',
    CSV_DOWNLOAD: 'CSV Downloaded Successfully',

    // RESPONSE MESSAGES
    SAVE_SUCCESS: 'Data Saved Successfully',
    UPDATE_SUCCESS: 'Data Updated Successfully',
    ERROR_MSG: 'Error in processing Data',
    EMPTY_RESPONSE: 'No Response Received',
    // Partners
    PARTNER_APPROVED: 'Partner Successfully Approved',
    PARNTER_ERROR: 'Error on Partner Approval',

    // Configurations
    NO_CHANGES: 'No changes detected',

    NEED_ADDRESS: 'Need Minimum One Address',
    // Orders
    ORDER_UPDATED: 'Order Updated Successfully',
    ORDER_ERROR: 'No Response Received',
    ORDER_CREATED: 'Order Created Successfully',
    // Estimate
    EST_UPDATED: 'Estimate Updated Successfully',
    EST_ERROR: 'No Response Received',
    EST_CREATED: 'Estimate Created Successfully',

    // EXPORT
    FILE_DOWNLOADED: 'File Downloaded Successfully',
    ERROR_DOWNLOAD: 'No Response Received',

    // Stock
    STOCK_UPDATED: 'Stock Updated Successfully',
    STOCK_ERROR: 'Error Data, Contact Admin',

    // Category
    CATEGORY_ADDED: 'Category Added Successfully',
    CATEGORY_UPDATED: 'Category Updated Successfully',
    PARENT_CATEGORY_ERROR: 'Category and parent category should not be same',

    // Delivery
    DELIVERY_SLIP_DOWNLOADED: 'Delivery Slip Downloaded Successfully',
    INVOICE_DOWNLOADED: 'Invoice Downloaded Successfully',
    DELIVERY_SLIP_ERROR_DOWNLOAD: 'No Response Received, Contact Admin',

    // hr
    LEAVE_APPLIED: 'Leave Applied Successfully',
    LEAVE_ERROR: 'Not Applied, Try after Sometime',
    LEAVE_REJECTED: 'Leave Rejected',
    LEAVE_APPROVED: 'Leave Approved',
    KPI_APPROVED_SUCCESS: 'Approved Successfully',

    NO_LEAVE_ADMIN: `No leave history for this employee..`,
    NO_LEAVE_EMPLOYEE: `No leaves! You're a Hero..`,
    MOBILE_OR_EMAIL_MANDATORY: 'Mobile Number or Email id is Mandatory',
    LEAD_ADDED_SUCCESS_MSG: 'Lead Added Successfully',
    LEAD_UPDATED_SUCCESS_MSG: 'Lead Updated Successfully',

    CONVERT_TO_MSG: 'Are you sure you want to convert into ',
    DELETE_LEAD_CONFIRM_MSG: 'Are you sure you want to Delete the Lead?',
    DELETE_LEAD_SUCCESS_MSG: 'Lead Deleted Successfully',
    // CONVERT_TO_SELLER: 'Are you sure you want to convert into Partner'

    // Call
    CALL_UPDATED_SUCCESS_MSG: 'Call Updated Successfully',
    PURPOSE_CANNOT_BE_EMPTY_MSG: 'Purpose must not be empty',
    FOLLOW_UP_ALREADY_DONE_MSG: 'Follow up already done',

    // Catalog
    CATALOG_CREATED_SUCCESS_MSG: 'Catalog sheet created successfully',
    // Customer
    CONFIRM_CONVERSION: 'Confirm Conversion',
    CONFIRM_UPDATE: 'Confirm Update',
    CONFIRM_CONVERT_TO_PARTNER_MSG: 'Are you sure to convert Customer into Partner?',
    CONFIRM_CUSTOMER_BIZ_UPDATE: 'Are you sure to change Customer type to %s?',

    // Validation
    ENTER_VALID_TEXT: 'Enter valid',
    PROFILE_UPDATED: 'Profile Image Uploaded Successfully',
    PROFILE_ERROR: 'Cant upload now try after sometime',

    ATTACHMENT_ADDED:'Attachment added successfully',
    // Vendor
    VENDOR_CREATED: 'Vendor Created Successfully',

    TRY_AFTER_SOMETIMES: 'Try After sometimes',
    GST_CONTACT_ADMIN_ERROR: 'Contact admin for the error',

    RECURRING_DATE_UNAVAILABLE_MESSAGE: 'If any of the selected month has no such date, the activity will not be scheduled for those particular months.',

    ATTRIBUTE_ADDED: 'Attribute Added Successfully',
    ATTRIBUTE_UPDATED: 'Attribute Updated Successfully',
    TERM_ADDED:'Term added Successfully',
    TERM_UPDATED:'Term Updated Successfully',

    // Expense 
    SUM_AMOUNT_MUST_BE_LESS_THAN_TOTAL: 'Sum Amount must be less than total',
    INVOICE_COPY_MISSING: 'Invoice Copy missing',
    PAYMENT_TOTAL_MISMATCH: 'Payment Total mismatch',
    VENDOR_MUST_BE_SELECTED_FOR_CREDIT_NOTE_EXPENSE_TYPE: 'Vendor must be selected for Credit Note expense type',
    EXPENSE_UPDATED_SUCCESSFULLY: 'Expense Updated Successfully',
    EXPENSE_ADDED_SUCCESSFULLY: 'Expense Added Successfully',
    CREDIT_NOTE_ALREADY_USED_IN_THIS_EXPENSE: 'Credit Note already used in this expense',
    VENDOR_CANT_UPDATE_SINCE_CREDIT_NOTES_ADDED: "Vendor can't update since credit notes added",
    PLEASE_REMOVE_CREDIT_NOTES_TO_EDIT_VENDOR: "Please remove credit notes to edit vendor",
    REDEEM_AMOUNT_MUST_BE_LESS_THAN_CREDIT_BALANCE_RS: 'Redeem Amount must be less than credit balance Rs.',

    INVOICE_GENERATED: 'Invoice Generated Successfully',

    //campaign
    CAMPAIGN_ADDED: 'Campaign Added Successfully',
    CAMPAIGN_UPDATED: 'Campaign Updated Successfully',
    UPLOAD_CSV: 'Please Upload as CSV File',
    MISSING_TEMPLATE_DETAILS: 'Missing Template Details',
    SELECT_TEMPLATE: 'Please Select Template',
    MISSING_TEMPLATE_VARIABLES: 'Missing Template Variables',
    SUCCESSFULL_CAMPAIGN_RUN:'Campaign Run is Successfull',
    ADD_TEMPlATE_ATTACHMENT: 'Please add Template attachment',
    ADD_START_TIME: 'Please add campaign start time',
    UPLOAD_VALID_IMAGE: 'Please Upload Valid Image',
    UPLOAD_VALID_FILE: 'Please Upload CSV or PDF',
    UPLOAD_VALID_VIDEO: 'Please Upload mp4 video',
    ADD_EMAILS: 'Please add Emails',
    ADD_MOBILE_NO: 'Please add Mobile Numbers',
    SELECT_AUDIENCE: 'Please Select Audience',
    APPROVAL_SUBMITTED: 'Submitted For Approval Successfully',
    CAMPAIGN_APPROVED: 'Campaign Approved Succesfully',
    CAMPAIGN_DELETED: 'Campaign Deleted Succesfully',

    CREDIT_INITIATED: 'Credit Initiated Successfully',

    COUPON_CREATED: 'Coupon Created Successfully',
    COUPON_UPDATED: 'Coupon Updated Successfully',
    PERCENTAGE_ERROR: 'Percentage discount must be less than or equal to 100',
    COUPON_USAGE_COUNT_ERROR: 'Maximum usage count should be greater than limit per user',

    PRODUCT_ADDED_IN_CART: 'Product Added in Cart'
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'Local',
  appVersion: require('../../package.json').version + '-dev',
  secertKey: 'gjuZPLuhHaZaxgsS',
  url: 'https://api-airrestore.sarbajira.in',
  //url: 'http://localhost:6161'
};
export const RouteConst = {
  LOGIN: '/',
  OTP_LOGIN: '/otp',
  VERIFY_OTP: '/verify-otp',

  LIST_JOB: '/app/jobs',
  CREATE_JOB: '/app/jobs/create',
  EDIT_JOB: '/app/jobs/edit',
  VIEW_JOB: '/app/jobs/view',

  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password',

  HOME: '/home',
  APP_DASHBOARD: '/app/dashboard',

  ADD_USER: '/app/users/add-user',
  VIEW_USER: '/app/users/',

  VIEW_EMPLOYEES: '/app/employees',
  ADD_EMPLOYEE: '/app/employees/add',
  MANAGE_EMPLOYEE: '/app/employees/manage/',

  VIEW_SELLERS: '/app/sellers',
  ADD_SELLER: '/app/sellers/add',
  MANAGE_SELLER: '/app/sellers/manage/',

  VIEW_PARTNERS: '/app/partners',
  ADD_PARTNER: '/app/partners/add',
  MANAGE_PARTNER: '/app/partners/manage/',

  VIEW_CUSTOMER: '/app/customers',
  ADD_CUSTOMER: '/app/customers/add',
  MANAGE_CUSTOMER: '/app/customers/manage/',

  VIEW_TRANSPORTER: '/app/transporters',
  ADD_TRANSPORTER: '/app/transporters/add',
  MANAGE_TRANSPORTER: '/app/transporters/manage/',

  ADD_PRODUCT: '/app/products/add',
  VIEW_PRODUCT: '/app/products/view',
  EDIT_PRODUCT: '/app/products/edit',
  PRODUCTS: '/app/products',

  ADD_CATEGORY: '/app/products/categories/add',
  EDIT_CATEGORY: '/app/products/categories/edit/',
  VIEW_CATEGORY: '/app/products/categories/view',

  NEW_ATTRIBUTE: '/app/products/attributes/add',
  VIEW_ATTRIBUTE: '/app/products/attributes/view/',
  EDIT_ATTRIBUTE: '/app/products/attributes/edit/',
  VIEW_TERMS: '/terms/view/',

  EDIT_TERM: '/terms/edit/',
  NEW_TERM: '/terms/add/',
  ATTRIBUTE: '/app/products/attributes/',

  STOCK_IMPORT: '/app/tools/stocks/import',
  PINCODE_IMPORT: '/app/tools/pincode/import',
  HOLIDAY_IMPORT: '/app/tools/holidays/import',

  SCHEDULER: '/app/master/scheduler/',

  SALES_ADD: '/app/orders/sales/add',
  SALES_EDIT: '/app/orders/sales/edit',
  ESTIMATE_ADD: '/app/orders/estimate/add',
  ESTIMATE_EDIT: '/app/orders/estimate/edit',

  VIEW_LEADS: '/app/crm/leads/view',
  ADD_LEADS: '/app/crm/leads/add',
  EDIT_LEADS: '/app/crm/leads/edit',
  VIEW_ORDER: '/app/orders/view/',

  DASHBOARD_PROFILE: '/app/dashboard/profile',
  CATEGORY_EDIT: '/app/catalog/edit',
  CATEGORY_UPDATE: '/app/catalog/update',
  PREVIEW_SALES: '/app/orders/preview/sales/',
  STOCK: '/app/stocks',
  VIEW_SALES_ORDER: '/app/orders/view/sales',
  ORDER_PREVIEW: '/app/orders/preview/',
  PREVIEW_ESTIMATE: '/app/orders/preview/estimate',
  RETURN_PREVIEW: '/app/purchases/return/manage',
  PO_ORDER_PREVIEW: '/app/purchases/order/manage',
  CREDIT_PREVIEW: '/app/purchases/credit-note/preview',
  VIEW_LOGISTICS: '/app/purchases/logistic',
  CATALOG_SHEET: '/app/catalog/sheet',
  CATALOG_EDIT: '/app/catalog/edit',
  ORDER_MID: '/app/orders',

  VIEW_TECHNICIAN: '/app/technicians',
  MANAGE_TECHNICIAN: '/app/technicians/manage/',
  TECHNICIAN_ADD: '/app/technicians/add',

  INVENTORY_VIEW: '/app/inventory',
  DELIVERY_PLANNER: '/app/orders/planner/delivery',
  RETURN_PLANNER: '/app/orders/planner/return',
  ASSEMBLY_PLANNER: '/app/orders/planner/assembly',
  VIEW_SERVICES: '/app/services',
  MANAGE_SERVICES: '/app/services/manage',

  MANAGE_TICKET: '/app/support/tickets/manage',
  VIEW_TICKET: '/app/support/tickets',
  INVENTORY_PRODUCT_VIEW: '/app/inventory/product/',

  // Vendors
  MANAGE_VENDOR: '/app/vendors/manage/',
  ADD_VENDOR: '/app/vendors/add',
  VIEW_VENDOR: '/app/vendors',

  MANAGE_EXPENSE: '/app/purchases/expense/manage/',
  VIEW_EXPENSE: '/app/purchases/expenses',
  APPROVE_EXPENSE: '/app/purchases/expenses/approval',
  ADD_EXPENSE: '/app/purchases/expense/add',

  CALENDAR: '/app/tools/calendar',

  VIEW_DYNAMIC_LINKS: '/app/tools/dynamic-links',
  ADD_DYNAMIC_LINK: '/app/tools/dynamic-link/add',
  MANAGE_DYNAMIC_LINK: '/app/tools/dynamic-link/manage',

  VIEW_CAMPAIGNS: '/app/marketing/campaign',
  ADD_CAMPAIGN: '/app/marketing/campaign/add',
  EDIT_CAMPAIGN: '/app/marketing/campaign/manage',

  VIEW_COUPONS: '/app/marketing/coupons',	
  ADD_COUPON: '/app/marketing/coupon/add',	
  EDIT_COUPON: '/app/marketing/coupon/manage',

  VIEW_CARTS: '/app/marketing/carts',	
  MANAGE_CART: '/app/marketing/cart/manage',
  	
  VIEW_SESSIONS: '/app/marketing/sessions',
  VIEW_SESSION_EVENTS: '/app/marketing/session/events',
};

<section class="auth-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="log-in-box card">
          <div class="text-center m-b-20 p-t-125-rem">
            <img
              class="form-logo"
              src="./assets/images/logo.webp"
              alt="Furnify"
            />
          </div>
          <div class="p-125-rem" *ngIf="showBlock === 'forgotBlock'">
            <form
              class="md-float-material form-material"
              [formGroup]="forgotForm"
              (ngSubmit)="forgotAction()"
            >
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h4 class="text-center">
                    {{ passwordHeading }}
                  </h4>
                </div>
              </div>
              <div class="custom-container">
                <mat-form-field class="" appearance="outline">
                  <mat-label>Email ID</mat-label>
                  <input
                    matInput
                    placeholder="Enter your email"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted &amp;&amp; forgotForm.controls.email.errors }"
                    required
                  />
                  <mat-error *ngIf="email.invalid">{{
                    getErrorMessage()
                  }}</mat-error>
                </mat-form-field>
                <div class="row text-left m-b-1">
                  <div class="col-12">
                    <div class="text-right float-right col-6">
                      <a routerLink={{loginPage}} class="text-right f-b-5">Back to Login</a>
                    </div>
                  </div>
                </div>
                <div class="m-b-20">
                  <button
                    mat-raised-button
                    class="button-red"
                    [disabled]="!forgotForm.valid"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="p-125-rem pt-0" *ngIf="showBlock === 'otpBlock'">
            <form
              class="md-float-material form-material"
              [formGroup]="verifyForm"
              (ngSubmit)="verifyOtp()"
            >
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h4 class="text-center">Enter The OTP</h4>
                </div>
              </div>
              <div class="custom-container otp_verify">
                <p class="m-b-1">
                  <ng-otp-input
                    #ngOtpInput
                    (onInputChange)="otpChange($event)"
                    [config]="config"
                    required
                  ></ng-otp-input>
                  <input
                    type="text"
                    *ngIf="otp"
                    formControlName="votp"
                    value="{{ otp }}"
                    class="o-t-p"
                    hidden
                  />
                </p>
                <div class="row text-left m-b-1">
                  <div class="col-12">
                    <div class="d-inline-block col-6">
                      <a routerLink={{loginPage}} class="text-right f-b-5">Back to Login</a>
                    </div>

                    <div class="text-right float-right col-6">
                      <span (click)="resendOtp()" class="text-right f-b-5 r_otp">Resend OTP</span>
                    </div>
                  </div>
                </div>
                <div class="m-b-20">
                  <button mat-raised-button class="button-red">
                    Verify OTP
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="p-125-rem" *ngIf="showBlock === 'changeBlock'">
            <form
              class="md-float-material form-material"
              [formGroup]="changeForm"
              (ngSubmit)="changeAction()"
            >
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h4 class="text-center">New Password</h4>
                </div>
              </div>
              <div class="custom-container">
                <mat-form-field class="m-b-1" appearance="outline">
                  <mat-label>New Password</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    placeholder="Enter New Password"
                    [type]="hide ? 'password' : 'text'"
                    [ngClass]="{ 'is-invalid': submitted &amp;&amp; changeForm.controls.password.errors }"
                    required
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                  <mat-error *ngIf="pwd.invalid">{{
                    getpwdErrorMessage()
                  }}</mat-error>
                </mat-form-field>
                <mat-form-field class="m-b-1" appearance="outline">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    matInput
                    formControlName="confirmPassword"
                    placeholder="Enter Confirm Password"
                    [type]="hide ? 'password' : 'text'"
                    [ngClass]="{ 'is-invalid': submitted &amp;&amp; changeForm.controls.password.errors }"
                    required
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                  <mat-error *ngIf="pwd.invalid">{{
                    getpwdErrorMessage()
                  }}</mat-error>
                </mat-form-field>
                <div class="row text-left m-b-1">
                  <div class="col-12">
                    <div class="text-right float-right col-6">
                      <a routerLink={{loginPage}} class="text-right f-b-5">Back to Login</a>
                    </div>
                  </div>
                </div>
                <div class="m-b-20">
                  <button
                    mat-raised-button
                    class="button-red"
                    type="submit"
                    [disabled]="!changeForm.valid"
                  >
                    {{changePwdBtnLabel}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

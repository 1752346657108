import { ErrorHandler, Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { LoggerService } from './logger.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(
        private logger: LoggerService,
        private sessionStorage: SessionStorageService) {
        super();
    }

    handleError(error) {
        // Here you can provide whatever logging you want
        const errorString = `${error}`;
        const sessionErrors = this.sessionStorage.get('loggedErrors');
        const loggedErrorsArray = sessionErrors ? sessionErrors : [];

        if (loggedErrorsArray.length === 0) {
            this.setErrorInSession(error, loggedErrorsArray, errorString);
        } else if (!loggedErrorsArray.includes(errorString)) {
            this.setErrorInSession(error, loggedErrorsArray, errorString);
        }
    }

    setErrorInSession(error, loggedErrorsArray: string[], errorString: string) {
        loggedErrorsArray.push(errorString);
        this.sessionStorage.set('loggedErrors', loggedErrorsArray);
        this.logger.logError(error);
        super.handleError(error);
    }
}

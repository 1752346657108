export const AuthConst = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  ACCESS_EXPIRY: 'access_expiry',
  REFRESH_EXPIRY: 'refresh_expiry',
  ACCESS_EXPIRY_AT: 'access_expiry_at',
  REFRESH_EXPIRY_AT: 'refresh_expiry_at',
  ROLE: 'role',
  REFRESH_BODY: 'refresh_token',
  STRING_TOKEN: 'token',
  LOCK_SCREEN: 'lockScreen',
  SESSION_ID: 'sessionId'
};



import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Login } from '../_models/login';
import { Title } from '@angular/platform-browser';
import { RouteConst } from '../_const/route';
import { Constants } from '../_const/constants';
import SnackBar from '../_utils/snackbar';
import { AuthenticationService } from '../auth.service';
import { AuthConst } from '../_const/auth';
import { EndPoints } from '../_const/endpoints';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyOTPComponent implements OnInit {
  verifyForm: FormGroup;

  errorMessage: any;
  hide = true;
  loading = false;
  submitted = false;
  loginPage = RouteConst.LOGIN;

  votp = new FormControl('', [Validators.required]);

  currentUserName: string;
  resetToken: string;
  otp: string;

  login: Login = new Login();

  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
    inputStyles: {
      width: '50px',
      height: '50px'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    public session: SessionStorageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private titleService: Title,
    private authenticationService: AuthenticationService) {
    const loginData = localStorage.getItem(Constants.LOGIN_DATA);
    if (!loginData) {
      this.router.navigate([this.loginPage]);
    } else {
      this.login = JSON.parse(loginData);
    }

    this.titleService.setTitle('Verify OTP' + Constants.APP_TITLE);
  }

  ngOnInit() {
    this.verifyForm = this.formBuilder.group({
      votp: ['', [Validators.required]]
    });
  }

  otpChange(otp: any) {
    this.otp = otp;
    this.login.password = otp;
  }

  verifyOtp() {
    this.loading = true;
    this.authenticationService.verifyOTP(this.login).subscribe((response) => {
      this.authenticationService.initializeUser(response);
    }, (error) => {
      this.loading = false;
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }

  resendOtp() {
    this.authenticationService.resendOTP(this.login).subscribe((response) => {
      SnackBar.open(this.snackBar, 'OTP sent again to your Email ID', Constants.SUCCESS, Constants.SUCCESS);
    }, (error) => {
      SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR);
    });
  }
}

export const Constants = {
    //  login/forgot
    SIGNIN: 'Sign In',
    GETOTP: 'Get OTP',
    EMPTY_STRING: '',
    REQUIRED: 'required',
    REMEMBER: 'remember',
    YES: 'yes',
    NO: 'no',
    LOGIN_USERNAME: 'username',
    LOGIN_PASSWORD: 'password',
    CONFIRM_PWD: 'confirmPassword',
    EMAIL: 'email',
    MOBILE_NO: 'mobileNo',
    UNLOCK: 'Unlock',

    // Job
    CREATE_JOB: "Create Job",
    UPDATE_JOB: "Update Job",
    UPDATE_BTN: "Update",
    JOB_FILTERS: "job_filters",

    // General
    ID: 'id',
    DESC: 'desc',
    AGENTS: 'agents',
    INFINITE: 'infinite',
    STATUS: 'status',
    PRIORITY: 'priority',
    BRAND: 'brand',
    TYPES: 'types',
    APPLY_FILTER: 'applyFilter',
    INIT: 'init',
    VERIFY: 'Verify',
    LEAVE: 'leave',

    ADD_ATTACHMENT: 'Add Attachment',
    REPLACE_ATTACHMENT: 'Replace Attachment',

    //  URL
    URL_TYPE: 'type',
    UPDATE: 'update',
    EDIT: 'edit',
    URL_VIEW: 'view',
    ADD: 'add',

    NONE: 'none',
    INHERIT: 'inherit',

    // IMAGES
    IMAGE_FEATURE: 'feature',
    IMAGE_GALLERY: 'gallery',
    IMAGE_DIMENSION: 'dimension',
    IMAGE_FEATURE_HOVER: 'featureHover',
    FILES: 'files',
    IMAGE_TEXT: 'image',
    ICON_TEXT: 'icon',
    FILE: 'file',

    // ALERT
    SUCCESS: 'Success',
    ERROR: 'Error',
    NO_CHANGES_MADE: 'No changes Made',

    // Orders
    BILLING_TEXT: 'billing',
    SHIPPING_TEXT: 'shipping',

    // If conditions
    PERCENTAGE: 'percentage',
    AMOUNT: 'amount',
    PRODUCT: 'product',
    CUSTOMER: 'customer',

    // DATA-IMPORT
    INVENTORY: 'inventory',
    TEMPLATE: 'template',
    UNDEFINED: 'undefined',
    GENERIC: 'GENERIC',
    UPDATE_TEMPLATE: 'update_template',
    SAVE_TEMPLATE: 'save_template',
    SAVE_AS_NEW: 'save_as_new',
    TEXT: 'text',
    SAVE: 'Save',

    // Title
    APP_TITLE: ' | Air Restore',
    MANAGE_LEAD: 'Manage Leads',
    CALL_LIST_TITLE: 'Call Logs',
    OK: 'Ok',
    TECHNICIAN_MANAGE_TITLE: 'Manage Technician',

    // Category
    CATEGORIES_TITLE: 'Categories',
    EDIT_CATEGORY: 'Edit Category',
    NEW_CATEGORY: 'New Category',
    ADD_CATEGORY: 'Add Category',
    UPDATE_CATEGORY: 'Update Category',
    NO_PARENT: 'No Parent',

    // Attributes
    ATTRIBUTES: 'Attributes',
    NEW_ATTRIBUTE: 'New Attribute',
    ADD_ATTRIBUTE: 'Add Attribute',
    UPDATE_ATTRIBUTE: 'Update Attribute',
    EDIT_ATTRIBUTE: 'Edit Attribute',
    Add_TERM: 'Add Term',
    UPDATE_TERM:'Update Term',

    // PIPE
    INR: 'INR',
    RS: 'Rs.',

    TRANSPORTERS: 'transporters',
    TECHNICIANS: 'technicians',

    // FormFields
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PASS: 'pass',
    GST_NUMBER: 'gstNo',
    GST_NAME: 'gstName',
    ADDRESS: 'Address',
    GST_TREATMENT: 'gstTreatment',

    // FormField
    CUSTOMER_NAME: 'customerName',
    OWNER: 'owner',
    DESCRIPTION: 'description',
    TITLE: 'title',
    BANK_DETAILS: 'bankDetails',
    BANK_ACCOUNT_NAME: 'bankAccountName',
    BANK_ACCOUNT_NO: 'bankAccountNumber',
    BANK_IFSC: 'bankIfsc',
    ROLE: 'role',
    ADDITIONAL_ROLE: 'additionalRole',

    // Lead
    COMPANY: 'company',
    WEBSITE: 'website',
    SOURCE: 'source',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    CITY: 'city',
    STATE: 'state',
    PINCODE: 'pincode',
    EMAIL_ID: 'emailId',
    VOIDED: 'voided',
    SELLER: 'Seller',
    PARTNER: 'Partner',
    ORDER: 'Order',

    // Utils
    START_DATE_FORMAT: 'YYYY-MM-DDT00:00:00',
    END_TIME_FORMAT: 'YYYY-MM-DDT23:59:59',

    // Filter keys
    CALL_START_TIME: 'callStartTime',
    CALL_STATUS: 'callStatus',
    CALL_TYPE: 'callType',
    CALLER_NUMBER: 'callerNumber',
    DESTINATION_NUMBER: 'destNumber',
    CALL_NOT_ASSIGNED: 'Not Assigned',
    DOLLAR_SYMBOL_ENCRYPTED: '%24%24',
    VENDOR_ID: 'vendorId',
    TRANSPORTER_ID: 'transporterId',
    TECHNICIAN_ID: 'technicianId',
    REIMB_EMPLOYEE_ID: 'reimbEmpId',
    CREATED_AT: 'crtAt',
    ORDER_TYPE: 'orderType',
    SO: 'SO',
    REFERENCE_ID: 'referenceId',
    RELATED_NO: 'relatedNo',

    // Storage Names
    CALL_PARAMS: 'callParams',
    STATES: 'states',

    // API Params
    PARAM_PARENT_CAT_ID: 'parentCatId',
    PARAM_PURCHASE_TYPE: 'purchaseType',
    PARAM_SELLER_ID: 'sellerId',
    USER_ID: 'userId',
    CUST_ID: 'customerId',
    PARAM_CATALOGING_ENABLED: 'catalogingEnabled',

    // Vendor
    USERS_VENDOR: 'Vendors',
    USERS_ADD_VENDOR: 'Add Vendor',
    USERS_PREVIEW_VENDOR: 'Manage Vendor',

    AGENT_NAME: 'agentName',
    AGENT_ID: 'agentId',

    // UserType
    USER_TYPE_SELLER: 'seller',
    USER_TYPE_EMPLOYEE: 'employee',
    USER_TYPE_VENDOR: 'vendor',
    USER_TYPE_PARTNER: 'partner',
    USER_TYPE_TRANSPORTER: 'transporter',
    USER_TYPE_CUSTOMER: 'customer',
    USER_TYPE_TECHNICIAN: 'technician',

    DATA_SYNC: 'DATA SYNC',

    DATE_FORMAT: 'YYYY/MM/DD',
    FORMAT_DATE: 'YYYY-MM-DD',
    DATE_FORMAT_TIME: 'YYYY/MM/DD hh:mm:ss',
    FORMAT_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss',
    START_DATE: 'YYYY-MM-DDT00:00:00',
    END_DATE:'YYYY-MM-DDT23:59:59',

    SELECT_PARTICIPANT: 'Please select atleast 1 participant',
    VIRTUAL_SCROLL_RESPONSE_SIZE: 50,
    
    //search filter
    PLEASE_ENTER_VALUE: 'Please enter value in input',
    DEFAULT_DATE_RANGE: 14,

    //Campaign
    ADD_CAMPAIGN: 'Add Campaign',
    EDIT_CAMPAIGN: 'Edit Campaign',
    CREATE_CAMPAIGN: 'Create Campaign',
    UPDATE_CAMPAIGN: 'Update Campaign',
    CAMPAIGN_EMAIL: 'EMAIL',
    BULK_USERS: 'Bulk Users',
    SMS: 'SMS',
    WHATSAPP: 'WHATSAPP',
    MANUAL: 'MANUAL',
    SCHEDULED: 'SCHEDULED',
    VIDEO: 'Video',
    IMAGE: 'Image',
    HTML_TYPE: 'html',
    MOBILE: 'mobile',
    MOBILE_CAPS: 'MOBILE',

    ACTIVE_STATUS: 1,

    // Local Storage
    LOGIN_DATA: 'login-data',

    USERS_CUSTOMER: 'customers',
    USERS_ADD_CUSTOMER: 'Add Customer',
    CUSTOMER_LIST_TITLE: 'Customers',
    CUSTOMER_MANAGE_TITLE: 'Manage Customer',
    CUSTOMER_ADD_TITLE: 'Add Customer',
    USERS_UPDATE_CUSTOMER: 'Update Customer',
    USERS_UPDATING_CUSTOMER: 'Updating Customer',
    USERS_ADDING_CUSTOMER: 'Adding Customer',
    USERS_MANAGE_CUSTOMER: 'Manage Customer',
};

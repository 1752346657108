import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OTPLoginComponent } from './otp-login/otp-login.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
  { path: 'otp', component: OTPLoginComponent},
  { path: 'verify-otp', component: VerifyOTPComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'set-password', component: ForgotPasswordComponent },
  {
    path: 'app',
    loadChildren: () => import('./home/home.module').then(m => m.ArHomeModule), canActivate: [AuthGuardService]
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), NgSelectModule],
  exports: [RouterModule]
})

export class AppRoutingModule { }

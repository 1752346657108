import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortnamePipe implements PipeTransform {

  transform(fullName: string): any {
    fullName.split(' ').map(n => n[0]).join('');
    return fullName.substring(0, 2);
  }

}
